import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";

import H2 from "../components/H2"
import Post from '../components/Post';

import posts from '../data/posts';
import partners from '../data/partners';

export default function Home() {

  const numberOfPostsToShowPerPage = 5; // Posts per page

  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation(); // Hook to get the current URL
  
  // Get the page number from the URL, default to 1 if not present
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get('page') || '1', 10);

  const [currentPage, setCurrentPage] = useState(pageFromUrl); // State for current page

  // Whenever the page number changes, update the URL
  useEffect(() => {
    if (currentPage !== pageFromUrl) {
      navigate(`?page=${currentPage}`);
    }
  }, [currentPage, pageFromUrl, navigate]);

  // Calculate the index of posts to show on the current page
  const indexOfLastPost = currentPage * numberOfPostsToShowPerPage;
  const indexOfFirstPost = indexOfLastPost - numberOfPostsToShowPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Total number of pages
  const totalPages = Math.ceil(posts.length / numberOfPostsToShowPerPage);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-2/3">
        <H2 hrClassName="mb-0" title="Latest news" subtitle="Latest news about the Hub and its members" />
          {currentPosts.map((post, index) => (
            <div key={post.id}>
              <Post post={post} />
              {index < currentPosts.length - 1 && <hr className="w-1/6 border-bahyellow border-b border-t-0" />}
            </div>
          ))}
          <div className="flex justify-between items-center mt-4">
            <button 
              onClick={handlePrevPage} 
              disabled={currentPage === 1} 
              className="cursor-pointer rounded-full bg-bahyellow px-4 py-2 text-s disabled:invisible"
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button 
              onClick={handleNextPage} 
              disabled={currentPage === totalPages} 
              className="cursor-pointer rounded-full bg-bahyellow px-4 py-2 text-s disabled:invisible"
            >
              Next
            </button>
          </div>
      </div>
      <div className="w-full flex flex-col lg:w-1/3 lg:pl-12">
        <H2 title="Become a member" />
        <span className="mt-2 text-center">Our ecosystem of impact makers is growing</span>
        <Link to="/join-us" className="inline-block self-center mt-5 rounded-full bg-bahyellow px-6 py-3">JOIN THE HUB</Link>
        <H2 title="Partners" />
        <div className="flex flex-wrap items-center">
          {partners.map((partner) => (
            <a key={partner.link} className="w-1/3 lg:w-1/2 p-4" href={partner.link} target="_blank" rel="noopener noreferrer"><img src={partner.logo} alt={partner.name} /></a>
          ))}
        </div>
      </div>
    </div>
  );
}