import React, { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";

import Navigation from '../components/Navigation';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

export default function Root() {

  const location = useLocation(); // Hook to get the current URL

  // Scroll to the top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Navigation />
      <Banner />
      <div className="container mx-auto">
        <Outlet />
      </div>   
      <Footer />
    </>
  );
}
